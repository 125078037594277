import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';

// Redux Action
import { openReservationModal } from '../../../actions/Reservation/payoutModal';

// Translation
import messages from '../../../locale/messages';
import { convert } from '../../../helpers/currencyConvertion';

import s from './ReservationManagement.css';
import CurrencyConverter from '../../CurrencyConverter/CurrencyConverter';

class Refund extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    reservationState: PropTypes.string.isRequired,
    openReservationModal: PropTypes.any.isRequired,
    taxPrice: PropTypes.number,
    transactionData: PropTypes.shape({
      payerEmail: PropTypes.string.isRequired,
      paymentType: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      paymentMethodId: PropTypes.number,
      transactionId: PropTypes.string.isRequired
    }),
    cancelData: PropTypes.shape({
      refundToGuest: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    transactionData: null,
    cancelData: null
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(amount, currency) {
    const {
      id, transactionData, openReservationModal, changeState, taxPrice, isMonthly, reservationState,
    } = this.props;

    console.log('amount', amount);
    console.log('currency', currency);

    let initialData = {
      type: 'guest',
      reservationId: id,
      receiverEmail: transactionData?.payerEmail,
      receiverId: transactionData?.payerId,
      payerEmail: transactionData?.receiverEmail,
      payerId: transactionData?.receiverId,
      amount,
      currency,
      paymentMethodId: transactionData?.paymentMethodId,
      transactionId: transactionData?.transactionId,
      changeState,
      taxPrice
    };
      
    if ((reservationState === 'cancelled') && isMonthly) {

      // Divide the refund over multiple payments
      initialData = {
        ...initialData,
        multipleRefundsData: [
          {
            transactionId: 'trans 1',
            amount: 1000,
          },
          {
            transactionId: 'trans 2',
            amount: 2000,
          }
        ],
      };
    }

    openReservationModal('ReservationPaymentForm', initialData);
  }

  render() {
    const {
      id, transactionData, reservationState, cancelData, successRefund, selectedRefund, isMonthly, isRefunded,
      depositPercentageToRefund, checkOut, basePrice, base, rates, deposit, reservationCurrency,
    } = this.props;
    const { formatMessage } = this.props.intl;

    let amount = 0;
    let refundCurrency = transactionData.currency;

    // Check if there is a transaction of type "booking"
    if (!transactionData?.total || !transactionData?.currency) {
      return <span>{formatMessage(messages.transactionNotfound)}</span>;
    }

    if ((isRefunded) || (successRefund && successRefund.includes(id))) {
      return <span>{formatMessage(messages.completedLabel)}</span>;
    }

    if (reservationState === 'cancelled') {
      if (!cancelData?.refundToGuest || !cancelData?.currency) {
        return <span>No cancellation data to refund</span>
      }

      if (cancelData.refundToGuest === 0) {
        return <span>No refund applied</span>;
      }
    }

    if (selectedRefund?.includes(id)) {
      return <span className={s.processingtext}>{formatMessage(messages.processingLabel)}</span>;
    }

    if ((reservationState === 'expired')
      || (reservationState === 'declined')
      || (reservationState === 'cancelled')
      || ((reservationState === 'completed') && isMonthly)
      || ((reservationState === 'not-renewed') && isMonthly)
    ) {
      
      let depositAmountToRefund = (Number(deposit) * Number(depositPercentageToRefund)) / 100;

      if (isMonthly) {

        if (!depositPercentageToRefund && (
          (reservationState === 'completed')
          || (reservationState === 'cancelled')
          || (reservationState === 'not-renewed')
        )) {
          return <span>Pending host's confirmation</span>;
        }

        if (reservationState === 'not-renewed') {

          // Checkout still not done
          if ((moment(checkOut).diff(moment(), 'days')) < 0) {
            return <span>Pending guest's check-out</span>;
          }

          if (depositAmountToRefund > 0) {

            // deduct part of deposit as last month is 30 x night price
            let missedAmountFromGuest = (30 * Number(basePrice)) - Number(deposit);
            depositAmountToRefund = depositAmountToRefund - missedAmountFromGuest;
          }

          if (depositAmountToRefund <= 0) {
            return <span>No refund applied</span>;
          }
          
          amount = convert(base, rates, depositAmountToRefund, reservationCurrency, refundCurrency);
        }
      }

      if ((reservationState === 'expired') || (reservationState === 'declined')) {
        amount = transactionData.total; // including deposit if it is monthly
      }

      if (reservationState === 'completed') {
        amount = convert(base, rates, depositAmountToRefund, reservationCurrency, refundCurrency);
      }

      if (reservationState === 'cancelled') {
        amount = convert(base, rates, (Number(cancelData.refundToGuest) + Number(depositAmountToRefund)), cancelData.currency, refundCurrency);
      }

      return (
        <div>
          <a onClick={() => this.handleClick(amount, refundCurrency)}>
            {formatMessage(messages.proceedRefund)}
            <br/>
            <CurrencyConverter
              amount={amount}
              from={refundCurrency}
            />
          </a>
        </div>
      );
    }
    else {
      return <span>{formatMessage(messages.notEligible)}</span>
    }
  }
}

const mapState = (state) => ({
  base: state.currency.base,
  rates: state.currency.rates
});

const mapDispatch = {
  openReservationModal
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Refund)));