import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux Form
import { Field, reduxForm, initialize, submit } from 'redux-form';
import { connect } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Form,
  Button
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import bt from '../../components/commonStyle.css';

// Components
import Summary from './Summary';
import DetailsForHost from './DetailsForHost';
import DetailsForGuest from './DetailsForGuest';
import NotFound from '../../routes/notFound/NotFound';
import Link from '../Link';
import Avatar from '../Avatar';

import validate from './validate';
import submitForm from './submit';
import messages from '../../locale/messages';
import { getDateRanges } from '../../helpers/dateRange';

import s from './Cancellation.css';

class Cancellation extends React.Component {

  static propTypes = {
    formatMessage: PropTypes.any,
    userType: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      listId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      guests: PropTypes.number.isRequired,
      hostId: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      basePrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      hostServiceFee: PropTypes.number.isRequired,
      taxPrice: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      picture: PropTypes.string,
      reservationState: PropTypes.string.isRequired,
      listData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        country: PropTypes.string,
        listingData: PropTypes.shape({
          cancellation: PropTypes.shape({
            id: PropTypes.number.isRequired,
            policyName: PropTypes.string.isRequired,
            priorDays: PropTypes.number.isRequired,
            accommodationPriorCheckIn: PropTypes.number.isRequired,
            accommodationBeforeCheckIn: PropTypes.number.isRequired,
            accommodationDuringCheckIn: PropTypes.number.isRequired,
            guestFeePriorCheckIn: PropTypes.number.isRequired,
            guestFeeBeforeCheckIn: PropTypes.number.isRequired,
            guestFeeDuringCheckIn: PropTypes.number.isRequired,
          })
        })
      }),
      messageData: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      hostData: PropTypes.shape({
        profileId: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        picture: PropTypes.string
      }),
      guestData: PropTypes.shape({
        profileId: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        picture: PropTypes.string,
      }),
    })
  };

  renderFormControlTextArea = ({ input, label, meta: { error, visited }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group className={s.space1}>
        <Form.Control
          {...input}
          className={className}
          as="textarea"
          placeholder={label}
          rows={6}
        >
          {children}
        </Form.Control>
        {visited && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </Form.Group>
    );
  }

  calculateCancellation(interval, nights) {
    const { data, data: { listData, isMonthly } } = this.props;

    let cancellation = data?.cancellation || listData.listingData.cancellation;

    let cancellationRuleObj = {};

    let type = null;
    if (isMonthly) {
      if (interval > 7) {
        type = 'priorCheckIn'
      }
      else if (interval <= 7 && interval > 0) {
        type = 'beforeCheckIn'
      }
      else {
        type = 'duringCheckIn'
      }
    }
    else {
      if (interval > cancellation?.priorDays) {
        type = 'priorCheckIn'
      }
      else if (interval <= cancellation?.priorDays && interval > 0) {
        type = 'beforeCheckIn'
      }
      else {
        type = 'duringCheckIn'
      }

      cancellationRuleObj = {
        ...cancellationRuleObj,
        priorDays: cancellation?.priorDays,
        policyName: cancellation?.policyName,
        policyContent: cancellation?.policyContent,
      }

      if (type == 'priorCheckIn') {
        cancellationRuleObj = {
          ...cancellationRuleObj,

          accomodation: cancellation?.accommodationPriorCheckIn,
          guestFees: cancellation?.guestFeePriorCheckIn,
          nonRefundableNights: cancellation?.nonRefundableNightsPriorCheckIn,

          cleaningFeePercent: 100,
          taxFeePercent: 100,
          isTaxRefunded: true
        }
      }
      else if (type == 'beforeCheckIn') {
        cancellationRuleObj = {
          ...cancellationRuleObj,

          accomodation: cancellation?.accommodationBeforeCheckIn,
          guestFees: cancellation?.guestFeeBeforeCheckIn,
          nonRefundableNights: cancellation?.nonRefundableNightsBeforeCheckIn,

          cleaningFeePercent: (cancellation.id === 3) ? 0 : 100,
          taxFeePercent: (cancellation.id === 3) ? 0 : 100,
          isTaxRefunded: (cancellation.id === 3) ? false : true
        }
      }
      else {
        cancellationRuleObj = {
          ...cancellationRuleObj,

          accomodation: cancellation?.accommodationDuringCheckIn,
          guestFees: cancellation?.guestFeeDuringCheckIn,
          nonRefundableNights: cancellation?.nonRefundableNightsDuringCheckIn,

          remainingNights: (nights - 1) + interval,

          cleaningFeePercent: 0,
          taxFeePercent: 0,
          isTaxRefunded: false
        }
      }
    }

    cancellationRuleObj = {
      ...cancellationRuleObj,
      nights,
      interval,
      type,
      isMonthly,
    }

    return cancellationRuleObj;
  }

  render() {
    const { userType, valid, data, submit } = this.props;
    const { formatMessage } = this.props.intl;

    console.log('cancellation data', data);

    const { nights, interval } = getDateRanges({ checkIn: data.checkIn, checkOut: data.checkOut, country: data.listData.country });
    console.log('nights', nights);
    console.log('interval', interval);

    if (data.guestData && data.hostData && data.messageData && data.listData && data?.checkIn && data?.checkOut && (-interval) < (nights - 1)) {
      let cancelData = this.calculateCancellation(interval, nights);
      console.log('cancelData', cancelData);

      return (
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} >
              <form className={s.displayContainerParent}>
                <div>
                  <Summary
                    userType={userType}
                    firstName={userType === 'host' ? data.guestData?.firstName : data.hostData?.firstName}
                    guests={data?.guests}
                    nights={nights}
                    interval={interval}
                    checkIn={data?.checkIn}
                    checkOut={data?.checkOut}
                    isMonthly={data?.isMonthly}
                    checkoutMonth={data?.checkoutMonth}
                  />
                  <hr className={s.horizontalLineThrough} />
                  <div className={s.space3}>
                    <p className={cx(s.landingSubTitle, 'textWhite')}>
                      <FormattedMessage {...messages.cancellation_reason_title} />
                    </p>
                    <div className={s.displayFlexAvatar}>
                      <Avatar
                        source={userType === 'host' ? data.guestData?.picture : data.hostData?.picture}
                        height={60}
                        width={60}
                        title={userType === 'host' ? data.guestData?.firstName : data.hostData?.firstName}
                        className={s.profileAvatar}
                        withLink
                        linkClassName={s.profileAvatarLink}
                        profileId={userType === 'host' ? data.guestData?.profileId : data.hostData?.profileId}
                      />
                      <p className={s.hostedText}>
                        {userType === 'guest' && <span><FormattedMessage {...messages.hostedBy} /> {' '}</span>}
                        {userType === 'host' ? <span>{data.guestData?.firstName}</span> : <span>{data.hostData?.firstName}</span>}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Field
                      className={s.textareaInput}
                      name="message"
                      component={this.renderFormControlTextArea}
                      label={(userType === 'host') ? formatMessage(messages.cancellation_reason_placeholder_from_host) : formatMessage(messages.cancellation_reason_placeholder_from_guest)}
                    />
                  </div>
                  <p className={cx(s.landingStepDesc, s.space5, 'textWhite')}>
                    <span><FormattedMessage {...messages.reservationCancel} /></span>
                  </p>
                  <Form.Group className={cx(s.formGroup, bt.textAlignRight, s.mobileCenter)}>
                    <Link
                      className={cx(bt.btnPrimaryBorder, bt.btnLarge, s.linkBtn, 'rtlCancelLinkBtl')}
                      to={userType === 'host' ? "/reservation/current" : "/trips/current"}
                    >
                      {userType === 'host' ? <FormattedMessage {...messages.keepReservation} /> : <FormattedMessage {...messages.keepTrip} />}
                    </Link>
                    <Button
                      className={cx(bt.btnPrimary, bt.btnLarge, s.mobileBtn)}
                      onClick={() => submit('CancellationForm')}
                      disabled={!valid}
                    >
                      {userType === 'host' ? <FormattedMessage {...messages.cancelYourReservation} /> : <FormattedMessage {...messages.cancelYourTrip} />}
                    </Button>
                  </Form.Group>
                </div>
                <div>
                  {
                    (userType === 'host') &&
                    <DetailsForHost
                      reservationData={data}
                      cancelData={cancelData}
                      firstName={data.guestData?.firstName}
                    />
                  }
                  {
                    (userType === 'guest') &&
                    <DetailsForGuest
                      reservationData={data}
                      cancelData={cancelData}
                    />
                  }
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return <NotFound />
    }
  }
}

Cancellation = reduxForm({
  form: 'CancellationForm',
  validate,
  onSubmit: submitForm
})(Cancellation);

const mapState = (state) => ({
});

const mapDispatch = {
  initialize
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Cancellation)));