import moment from 'moment';

//Discount calculation
export function getPriceWithDiscount({ basePrice, discount, nights }) {
  if (discount > 0) {
    let singleNightDiscount = discount / nights;
    basePrice = basePrice - singleNightDiscount;
  }
  return basePrice;
}

export function getFixedValue(value) {
  if (!value) return value;
  return Number(value.toFixed(2))
}

export function calculateGuestCancelValuesForNightly(
  remainingNights,
  nights,
  accomodation,
  isCleaningPrice,
  guestServiceFee,
  guestFees,
  hostServiceFee,
  taxPrice,
  basePrice, // Either special price average or base price from reserveration with discount is set
  total,
  nonRefunableNights,
  hostServiceFeeType,
  hostServiceFeeValue,
  cleaningFeePercent,
  taxFeePercent,
) {

  let refundToGuest = 0, notRefundableToGuest = 0, updatedHostFee = 0, payoutToHost = 0;
  let checkInNights = (remainingNights == 0 || remainingNights > 0) ? remainingNights : nights, hostRefund = 0;
  let totalNights = checkInNights - nonRefunableNights, isTaxPrice, hostRefundFee;

  //Based on the policy, update the guest fee
  let updatedGuestFee = (guestServiceFee * (guestFees / 100)), //This variable stores refunded guest fee value.
    paidAmount = total;

  //Based on the policy, update the cleaning price & taxPrice
  isCleaningPrice = (isCleaningPrice * (cleaningFeePercent / 100));
  isTaxPrice = (taxPrice * (taxFeePercent / 100));

  //Refund amount without considering guest service fee
  refundToGuest = getFixedValue(((totalNights * basePrice) * (accomodation / 100)) + isCleaningPrice + isTaxPrice);

  //Host Payout amount without subtracting host service fee. total has cleaning Fee & tax with in it.
  hostRefund = getFixedValue(total - refundToGuest);

  //Adding guest service fee, if it could be refunded
  refundToGuest = refundToGuest + updatedGuestFee;

  //calculate hostservicefee without tax
  hostRefundFee = isTaxPrice === 0 ? hostRefund - taxPrice : hostRefund;

  //Payout amount calculated with host service fee
  if (hostRefund > 0) {
    updatedHostFee = getFixedValue(hostServiceFeeType === 'percentage' ? hostRefundFee * (Number(hostServiceFeeValue) / 100) : hostServiceFee);
    payoutToHost = getFixedValue(hostRefund - updatedHostFee);
  }

  //Non refundable amount calculated based on the total amount guest paid and the refundable amount with guest service fee
  notRefundableToGuest = getFixedValue(paidAmount - refundToGuest);
  
  let actualCheckout = null;

  return {
    refundToGuest,
    payoutToHost,

    //If this updatedGuestFee with in object is 0, admin won't get guest service fee after cancellation
    guestServiceFee: guestServiceFee - updatedGuestFee, //Either whole guest service is refunded or none based on the policy
    hostServiceFee,
    
    notRefundableToGuest,
    
    actualCheckout,
  };
}

export function calculateHostCancelValuesForNightly({
  total,
  basePrice, // Either special price average or base price from reserveration with discount is set
  isCleaningPrice,
  nights,
  remainingNights,
  guestServiceFee, //Guest service fee value, when the reservation is created
  hostServiceFee, //Host service fee value, when the reservation is created
  taxPrice,
  hostServiceFeeType, //Host service fee set by admin at the time of creating reservation
  hostServiceFeeValue, //Host service fee type set by admin at the time of creating reservation
  interval
}) {

  let refundToGuest = 0, nonPayoutAmount = 0, payoutToHost = 0, hostRefund = 0, refundDays = nights;
  let updatedHostFee = hostServiceFee, updatedGuestFee = guestServiceFee, hostRefundFee, taxAmount = taxPrice;

  //If the cancellation is done after or during check in date
  if (interval <= 0 && remainingNights < nights) {
    refundDays = remainingNights;
    isCleaningPrice = 0;
    guestServiceFee = 0;
    taxPrice = 0;
  }

  //Refund amount to guest
  refundToGuest = getFixedValue((refundDays * basePrice) + isCleaningPrice + taxPrice);

  //Host Payout amount without subtracting host service fee. total has cleaning Fee & tax with in it.
  hostRefund = getFixedValue(total - refundToGuest);

  //calculate hostservicefee without tax
  hostRefundFee = taxPrice === 0 ? hostRefund - taxAmount : hostRefund;

  //Payout amount calculated with host service fee
  if (hostRefund > 0) {
    nonPayoutAmount = refundToGuest; //guest service fee and cleaning fee won't be here
    //New host service fee calculated based on the host refund amount.
    updatedHostFee = getFixedValue(hostServiceFeeType === 'percentage' ? hostRefundFee * (Number(hostServiceFeeValue) / 100) : hostServiceFee);
    payoutToHost = getFixedValue(hostRefund - updatedHostFee);
  }
  else {
    //Payout amount of host is zero
    nonPayoutAmount = getFixedValue(total - updatedHostFee);
    updatedGuestFee = 0; //Guest fee refunded
    updatedHostFee = 0;
  }

  //Adding guest service fee, if it could be refunded
  refundToGuest = refundToGuest + guestServiceFee;

  return {
    refundToGuest,
    payoutToHost,
    hostServiceFee: updatedHostFee,
    guestServiceFee: updatedGuestFee, //If this value is 0, admin won't get guest service fee after cancellation
    
    nonPayoutAmount,
    refundDays,
  };
}

export function calculateGuestCancelValuesForMonthly(data, type, interval) {

  let refundToGuest = 0;
  let payoutToHost = 0;
  let guestServiceFee = 0;
  let hostServiceFee = 0;
  let actualCheckout = null;
  let unblockDatesStarting = null;
  let notRefundableToGuest = 0;

  let payoutHostStarting = null;
  let refundToGuestFromFirst = 0;
  let refundToGuestFromLast = 0;
  let refundToGuestFromBeforeLast = 0;

  if ('priorCheckIn' === type) {  // Before at least 7 days from the check-in

    // Refund to guest payment of 1st month + cleaning price + deposit but deduct the guest fees
    guestServiceFee = data.reservationGuestServiceFee;
    refundToGuest = (data.reservationTotal - guestServiceFee); 
    notRefundableToGuest = guestServiceFee;

    // Payout to host nothing
    hostServiceFee = 0;
    payoutToHost = 0;

    // Guest will not even check-in to modify the check-out date
    actualCheckout = null;

    // by default unblock all dates
    unblockDatesStarting = null;
  }
  else if ('beforeCheckIn' === type) {  // Between 7 days and 1 day before check-in

    // Refund to guest deposit + cleaning price
    guestServiceFee = data.reservationGuestServiceFee;
    refundToGuest = data.cleaningPrice; 
    notRefundableToGuest = data.monthlyRentalPrice + guestServiceFee;

    // Payout to host 1st month but deduct the host fees
    hostServiceFee = data.reservationHostServiceFee;
    payoutToHost = data.monthlyRentalPrice - hostServiceFee;

    // Guest will not even check-in to modify the check-out date
    actualCheckout = null;
    
    // Keep first month blocked
    unblockDatesStarting = moment(data.checkIn).add(30, 'days').format('YYYY-MM-DD');
  }
  else { // Guest already checked-in, only checkout date will be modified

    let passedDaysFromCheckin = Math.abs(interval);
    let currentMonth = Math.ceil(passedDaysFromCheckin / 30); 
    let usedDaysFromMonth = passedDaysFromCheckin - (currentMonth * 30);
    let usedDaysFromMonthProportion = usedDaysFromMonth / 30;

    if (data.completedPayments === currentMonth) { // Before renewing

      // Refund to guest deposit only
      guestServiceFee = data.reservationGuestServiceFee;
      refundToGuest = 0; 
      notRefundableToGuest = data.monthlyRentalPrice * (1 - usedDaysFromMonthProportion);

      // Payout to host this month regularly and deduct the host fees
      hostServiceFee = data.reservationHostServiceFee;
      payoutToHost = (data.monthlyRentalPrice + data.cleaningPrice) - hostServiceFee;

      // Checkout today
      actualCheckout = moment().format('YYYY-MM-DD');

      // payout starting one day after finishing the current month
      payoutHostStarting = moment(data.checkIn).add((currentMonth * 30) + 1, 'days');
      
      // Keep the remaining days of this month blocked and unblock any other months
      unblockDatesStarting = moment(data.checkIn).add(currentMonth * 30, 'days').format('YYYY-MM-DD');
    }
    else if (data.completedPayments === (currentMonth + 1)) { // After renewing

      // Refund to guest deposit and cleaning fee of the next month
      guestServiceFee = data.reservationGuestServiceFee * 2;
      refundToGuestFromLast = data.cleaningPrice;
      refundToGuest = refundToGuestFromLast; 
      notRefundableToGuest = data.reservationTotal + data.monthlyRentalPrice * (1 - usedDaysFromMonthProportion);

      // Payout to host this and next month and deduct the host fees twice
      hostServiceFee = data.reservationHostServiceFee * 2;
      payoutToHost = ((data.monthlyRentalPrice + data.cleaningPrice) * 2) - hostServiceFee;

      // Checkout today
      actualCheckout = moment().format('YYYY-MM-DD');

      // payout starting one day after finishing the next month
      payoutHostStarting = moment(data.checkIn).add(((currentMonth + 1) * 30) + 1, 'days');
      
      // Keep the remaining days of this month and next month blocked and unblock any other months
      unblockDatesStarting = moment(data.checkIn).add((currentMonth + 1) * 30, 'days').format('YYYY-MM-DD');
    }
    else {

      console.log('Logical Error, Cannot have paid months more than the coming month');
      return {};
    }
  }

  return {
    refundToGuest,
    payoutToHost,
    guestServiceFee,
    hostServiceFee: (hostServiceFee > 0) ? hostServiceFee : 0,
    actualCheckout,
    unblockDatesStarting,

    notRefundableToGuest,

    payoutHostStarting,
    refundToGuestFromFirst,
    refundToGuestFromLast,
    refundToGuestFromBeforeLast,
  };
}

export function calculateHostCancelValuesForMonthly(data, type, interval) {

  let refundToGuest = 0;
  let payoutToHost = 0;
  let guestServiceFee = 0;
  let hostServiceFee = 0;
  let actualCheckout = null;
  let unblockDatesStarting = null;
  let expectedMonthlyPayout = data.reservationTotal - data.reservationGuestServiceFee - data.reservationHostServiceFee;

  let nonPayoutAmount = 0;

  if ('priorCheckIn' === type) {  // Before at least 7 days from the check-in

    // Refund to guest everything
    guestServiceFee = 0;
    refundToGuest = data.reservationTotal; 

    // Payout to host nothing
    hostServiceFee = 0;
    payoutToHost = 0;
    nonPayoutAmount = data.reservationTotal - data.reservationGuestServiceFee - data.reservationHostServiceFee;

    // Guest will not even check-in to modify the check-out date
    actualCheckout = null;
  }
  else if ('beforeCheckIn' === type) {  // Between 7 days and 1 day before check-in

    // Refund to guest everything
    guestServiceFee = 0;
    refundToGuest = data.reservationTotal; 

    // Payout to host nothing
    hostServiceFee = 0;
    payoutToHost = 0;
    nonPayoutAmount = data.reservationTotal - data.reservationGuestServiceFee - data.reservationHostServiceFee;

    // Guest will not even check-in to modify the check-out date
    actualCheckout = null;
  }
  else { // Guest already checked-in, only checkout date will be modified

    let passedDaysFromCheckin = Math.abs(interval);
    let currentMonth = Math.ceil(passedDaysFromCheckin / 30); 
    let usedDaysFromMonth = passedDaysFromCheckin - (currentMonth * 30);
    let usedDaysFromMonthProportion = usedDaysFromMonth / 30;

    if (data.completedPayments === currentMonth) { // Before renewing

      // Refund to guest deposit + remaining days % for monthly rental
      guestServiceFee = data.reservationGuestServiceFee * usedDaysFromMonthProportion;
      refundToGuest = data.monthlyRentalPrice * (1 - usedDaysFromMonthProportion);

      // Payout to host used days only and deduct full month host fees
      hostServiceFee = data.reservationHostServiceFee;
      payoutToHost = (data.monthlyRentalPrice * usedDaysFromMonthProportion) + data.cleaningPrice - hostServiceFee;
      nonPayoutAmount = (data.monthlyRentalPrice * (1 - usedDaysFromMonthProportion));

      // Continue this month and then checkout
      // actualCheckout = moment(checkIn).add(currentMonth * 30, 'days');

      // Checkout today
      actualCheckout = moment().format('YYYY-MM-DD');
    }
    else if (data.completedPayments === (currentMonth + 1)) { // After renewing

      // Refund to guest deposit + remaining days % for monthly rental + full renewal payment
      guestServiceFee = data.reservationGuestServiceFee * (1 + usedDaysFromMonthProportion);
      refundToGuest = data.reservationTotal + data.monthlyRentalPrice * (1 - usedDaysFromMonthProportion);

      // Payout to host used days only and deduct full month host fees
      hostServiceFee = data.reservationHostServiceFee * 2;
      payoutToHost = (data.monthlyRentalPrice * usedDaysFromMonthProportion) + data.cleaningPrice - hostServiceFee;
      nonPayoutAmount = (data.monthlyRentalPrice * (1 - usedDaysFromMonthProportion));

      // Continue the next month also and then checkout
      // actualCheckout = moment(checkIn).add((currentMonth + 1) * 30, 'days');

      // Checkout today
      actualCheckout = moment().format('YYYY-MM-DD');
    }
    else {

      console.log('Logical Error, cannot have paid months more than the coming month');
      return {};
    }
  }

  return {
    refundToGuest,
    payoutToHost,
    guestServiceFee,
    hostServiceFee,
    actualCheckout,
    unblockDatesStarting,
    
    nonPayoutAmount,
    expectedMonthlyPayout,
  };
}