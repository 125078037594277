import { gql } from 'react-apollo';
import {
  CANCEL_RESERVATION_START,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_STATE_ERROR
} from '../../constants';
import history from '../../core/history';
import showToaster from '../../helpers/showToaster';
import getAllReservationQuery from './getAllReservationQuery.graphql';

export function cancel({
  reservationId,
  refundToGuest,
  payoutToHost,
  guestServiceFee,
  hostServiceFee,
  cancelledBy,
  message,
  userType,
  isTaxRefunded,
  actualCheckout,
  unblockDatesStarting,
}) {
  return async (dispatch, getState, { client }) => {

    dispatch({
      type: CANCEL_RESERVATION_START,
    });

    try {

      const isAuthenticated = getState().runtime;

      const mutation = gql`
        mutation cancelReservation(
          $reservationId: Int!,
          $refundToGuest: Float!,
          $payoutToHost: Float!,
          $guestServiceFee: Float!,
          $hostServiceFee: Float!,
          $cancelledBy: String!,
          $message: String!,
          $isTaxRefunded: Boolean!,
          $actualCheckout: String,
          $unblockDatesStarting: String,
        ){
          cancelReservation(
            reservationId: $reservationId,
            refundToGuest: $refundToGuest,
            payoutToHost: $payoutToHost,
            guestServiceFee: $guestServiceFee,
            hostServiceFee: $hostServiceFee,
            cancelledBy: $cancelledBy,
            message: $message,
            isTaxRefunded: $isTaxRefunded,
            actualCheckout: $actualCheckout,
            unblockDatesStarting: $unblockDatesStarting
          ) {
            status
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: {
          reservationId,
          refundToGuest,
          payoutToHost,
          guestServiceFee,
          hostServiceFee,
          cancelledBy,
          message,
          isTaxRefunded,
          actualCheckout,
          unblockDatesStarting,
        },
        refetchQueries: [
          {
            query: getAllReservationQuery,
            variables: {
              userType,
              currentPage: 1,
              dateFilter: 'current'
            },
          }
        ]
      });

      if (data?.cancelReservation && data?.cancelReservation?.status === '200') {
        dispatch({
          type: CANCEL_RESERVATION_SUCCESS,
        });
        showToaster({ messageId: 'cancelReservation', toasterType: 'success' })
        if (!isAuthenticated?.isAuthenticated) {
          history.push('/siteadmin/reservations');
        } else {
          if (cancelledBy === 'host') {
            history.push('/reservation/current');
          } else {
            history.push('/trips/current');
          }
        }
      }

      if (data?.cancelReservation && data?.cancelReservation?.status === '400') {
        dispatch({
          type: CANCEL_RESERVATION_SUCCESS,
        });
        showToaster({ messageId: 'cancelReservError', toasterType: 'error' })
        history.push('/siteadmin/reservations');
      }

    } catch (error) {
      dispatch({
        type: CANCEL_RESERVATION_STATE_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}