import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { flowRight as compose } from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Payout from './Payout';
import Refund from './Refund';
import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';
import ModalForm from './ModalForm';
import CustomPagination from '../../CustomPagination';

import messages from '../../../locale/messages';
import formatReservationState from '../../../helpers/formatReservationState';
import { debounce } from '../../../helpers/debounce';
import history from '../../../core/history';
import { getDateRanges } from '../../../helpers/dateRange';
import showToaster from '../../../helpers/showToaster';

import ExportImage from '../../../../public/adminIcons/export.png';

import s from './ReservationManagement.css';
import MonthlyPayout from './MonthlyPayout';


class ReservationManagement extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.any.isRequired,
      getTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        reservationData: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          listId: PropTypes.number.isRequired,
          hostId: PropTypes.string.isRequired,
          guestId: PropTypes.string.isRequired,
          checkIn: PropTypes.string.isRequired,
          checkOut: PropTypes.string.isRequired,
          guestServiceFee: PropTypes.number.isRequired,
          hostServiceFee: PropTypes.number.isRequired,
          taxPrice: PropTypes.number.isRequired,
          total: PropTypes.number.isRequired,
          currency: PropTypes.string.isRequired,
          reservationState: PropTypes.string.isRequired,
          listData: PropTypes.shape({
            title: PropTypes.string.isRequired
          }),
          hostData: PropTypes.shape({
            profileId: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired
          }),
          hostPayout: PropTypes.shape({
            id: PropTypes.number.isRequired,
            payEmail: PropTypes.string.isRequired,
            methodId: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            last4Digits: PropTypes.number
          }),
          hostTransaction: PropTypes.shape({
            id: PropTypes.number.isRequired,
          }),
          guestData: PropTypes.shape({
            profileId: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired
          }),
          transaction: PropTypes.shape({
            payerEmail: PropTypes.string.isRequired,
            paymentType: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            paymentMethodId: PropTypes.number
          }),
          refundStatus: PropTypes.shape({
            id: PropTypes.number.isRequired,
            receiverEmail: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired
          }),
          cancellationDetails: PropTypes.shape({
            refundToGuest: PropTypes.number.isRequired,
            payoutToHost: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            guestServiceFee: PropTypes.number.isRequired,
            hostServiceFee: PropTypes.number.isRequired,
            isTaxRefunded: PropTypes.bool.isRequired
          }),
        })),
      }),
    }),
    viewReceiptAdmin: PropTypes.any,
  };

  static defaultProps = {
    getAllReservations: {
      loading: true,
      getAllReservationAdmin: {
        count: null,
        reservationData: []
      }
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      selectedRefund: [],
      successRefund: [],
      selectedPayout: [],
      successPayout: [],
    };
  }

  changeState = (type, value) => {
    const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;
    const { searchList, currentPage, getAllReservations: { refetch } } = this.props;
    let variables = {};

    if (type === 'addRefund') variables = { selectedRefund: [...selectedRefund, value] };

    if (type === 'removeRefund') {
      let index = selectedRefund.findIndex(i => i === value);
      if (index === -1) return '';
      let data = [...selectedRefund];
      data.splice(index, 1)
      variables = { selectedRefund: data };
    }

    if (type === 'successRefund') variables = { successRefund: [...successRefund, value] };

    if (type === 'addPayout') variables = { selectedPayout: [...selectedPayout, value] };

    if (type === 'removePayout') {
      let index = selectedPayout.findIndex(i => i === value);
      if (index === -1) return '';
      let data = [...selectedPayout];
      data.splice(index, 1)
      variables = { selectedPayout: data };
    }
    if (type === 'successPayout') {
      variables = { successPayout: [...successPayout, value] };
      refetch({ currentPage, searchList });
    }
    this.setState(variables)
  }

  paginationData = (currentPage) => {
    const { getAllReservations: { refetch }, changeStateValues, searchList } = this.props;
    let variables = { currentPage: currentPage || this.state.currentPage, searchList };
    changeStateValues({ currentPage });
    this.setState(variables)
    refetch(variables);
  }

  handleSearchChange = (e) => {
    const { getAllReservations: { refetch }, changeStateValues } = this.props;
    let variables = {
      currentPage: 1,
      searchList: e.target.value,
    };
    changeStateValues({
      currentPage: 1,
      searchList: e.target.value,
    });
    refetch(variables);
  }

  takeAction = async (id, type) => {
    const { getAllReservations: { refetch } } = this.props;
    let query = `query checkReservationData ($id:Int,$type:String){
          checkReservationData (id:$id,type:$type){
              status,
              errorMessage
          }
      }`;

    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { id, type },
      }),
      credentials: 'include',
    });

    const { data } = await resp.json();
    if (data?.checkReservationData?.status == '200') {
      if (type == 'cancellation') {
        history.push("/siteadmin/cancel/" + id + '/' + 'host')
        return true
      }
    } else {
      showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.checkReservationData?.errorMessage })
      let variables = {
        searchKey: '',
        currentPage: 1
      };
      await refetch(variables);
      return false
    }
  }

  isEnableToCancel = (reservationData) => {
    const { nights, interval, today } = getDateRanges({ checkIn: reservationData?.checkIn, checkOut: reservationData?.checkOut, country: reservationData?.listData?.country });
    return (
      reservationData?.reservationState === 'approved' && moment(reservationData?.checkOut) > today && (-interval) < (nights - 1) ?
        <span onClick={() => this.takeAction(reservationData?.id, 'cancellation')} className={s.cancelBtn}>
          <FormattedMessage {...messages.deSelect} />
        </span> : <div>
          -
        </div>
    );
  }


  render() {
    const { data, title, getAllReservations, toCurrency, currentPage, searchList, payoutStatus } = this.props;
    const { getAllReservations: { loading, getAllReservationAdmin, refetch } } = this.props;
    const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;
    const { formatMessage } = this.props.intl;
    let userType = 'host';

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <ModalForm />
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.adminManageReservation} /></h1>
          <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
            <div>
              <Form.Control
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={(e) => this.handleSearchChange(e)}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </div>
            <div>
              {
                getAllReservationAdmin?.reservationData && getAllReservationAdmin?.reservationData?.length > 0 && <a
                  href={`/export-admin-data?type=reservations&keyword=${searchList}&toCurrency=${toCurrency}`}
                  className={cx(s.exportText, 'commonFloatLeft', 'textWhite')}>
                  <span className={s.vtrMiddle}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                  <span className={cx(s.exportLinkImg, 'exportLinkImgCommon')}>
                    <img src={ExportImage} className={s.exportImg} />
                  </span>
                </a>
              }
            </div>
          </div>
          <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.reservationId)}</th>
                  <th scope="col">{formatMessage(messages.codeLabel)}</th>
                  <th scope="col">{formatMessage(messages.adminListTitle)}</th>
                  <th scope="col">{formatMessage(messages.bookingStatus)}</th>
                  <th scope="col">{formatMessage(messages.bookingAction)}</th>
                  <th scope="col">Monthly Payout</th>
                  <th scope="col">{formatMessage(messages.refundToGuest)}</th>
                  <th scope="col">{formatMessage(messages.subTotalLabel)}</th>
                  <th scope="col">{formatMessage(messages.payoutLabelAdmin)}</th>
                  <th scope="col">{formatMessage(messages.details)}</th>
                  <th scope="col">{formatMessage(messages.setEnableDisable)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  getAllReservationAdmin && getAllReservationAdmin.reservationData && getAllReservationAdmin.reservationData.length > 0 ?
                    getAllReservationAdmin.reservationData.map((value, index) => {
                      let subTotal = value.total;
                      return (
                        <tr key={index}>
                          <td>{value.id}</td>
                          <td>{value.confirmationCode}</td>
                          <td>
                            {
                              value.listData ?
                                <a href={"/rooms/" + value.listId} target='_blank'>{value.listData.title}</a>
                                : (
                                  formatMessage(messages.dataMissing)
                                )
                            }
                          </td>
                          <td className={s.ChangeToUpperCase}>{formatReservationState(value.reservationState)}</td>
                          <td>
                            {
                              value?.reservationState == 'pending' ?
                                <a onClick={() => this.takeAction(value.id, 'request')} target="_blank" href={"/message/" + value?.threadData?.threadId + "/" + userType}>
                                  <FormattedMessage {...messages.manageLabel} />
                                </a>
                                :
                                <p>-</p>
                            }
                          </td>
                          <td>
                            {
                              value?.isMonthly && ('approved' === value?.reservationState) ?
                                <MonthlyPayout
                                  hostId={value?.hostId}
                                  checkIn={value?.checkIn}
                                  id={value?.id}
                                  hostPayout={value?.hostPayout}
                                  amount={value?.total - value?.guestServiceFee}
                                  currency={value?.currency}
                                  hostTransaction={value?.hostTransaction}
                                  hostData={value?.hostData}
                                  hostServiceFee={value?.hostServiceFee}
                                  country={value?.listData ? value?.listData?.country : ''}
                                  selectedPayout={selectedPayout}
                                  successPayout={successPayout}
                                  changeState={this.changeState}

                                  checkoutMonth={value?.checkoutMonth}
                                  completedPayments={value?.completedPayments}
                                  completedPayouts={value?.completedPayouts}
                                />
                                :
                                <p>-</p>
                            }
                          </td>
                          <td>
                            <Refund
                              id={value?.id}
                              reservationState={value?.reservationState}
                              transactionData={value?.transaction}
                              renewalTransactionsData={value?.renewalTransactions}
                              refundData={value?.refundStatus}
                              cancelData={value?.cancellationDetails}
                              selectedRefund={selectedRefund}
                              changeState={this.changeState}
                              successRefund={successRefund}
                              taxPrice={value?.cancellationDetails?.isTaxRefunded === true ? value?.taxPrice : 0}
                              isMonthly={value?.isMonthly}
                              depositPercentageToRefund={value?.depositPercentageToRefund}
                              isRefunded={value?.isRefunded}
                              deposit={value?.deposit}
                              reservationCurrency={value?.currency}
                              checkOut={value?.checkOut}
                              basePrice={value?.listData.listingData.basePrice}
                            />
                          </td>
                          <td>
                            <CurrencyConverter
                              amount={subTotal}
                              from={value.currency}
                            />
                          </td>
                          <td>
                            <Payout
                              hostId={value?.hostId}
                              checkIn={value?.checkIn}
                              id={value?.id}
                              hostPayout={value?.hostPayout}
                              amount={value?.total - value.guestServiceFee}
                              currency={value?.currency}
                              hostTransaction={value?.hostTransaction}
                              reservationState={value?.reservationState}
                              cancelData={value?.cancellationDetails}
                              hostData={value?.hostData}
                              hostServiceFee={value?.hostServiceFee}
                              country={value?.listData ? value?.listData?.country : ''}
                              selectedPayout={selectedPayout}
                              successPayout={successPayout}
                              changeState={this.changeState}
                              taxPrice={value?.cancellationDetails?.isTaxRefunded === false || value?.reservationState === 'completed' ? value?.taxPrice : 0}

                              completedPayments={value?.completedPayments}
                              completedPayouts={value?.completedPayouts}
                            />
                          </td>
                          <td>
                            <Link to={"/siteadmin/viewreservation/" + value?.id + '/reservation'} >
                              <FormattedMessage {...messages.viewLabel} />
                            </Link>
                          </td>
                          <td>
                            {this.isEnableToCancel(value)}
                          </td>
                        </tr>
                      )
                    })
                    : (
                      <tr><td colSpan="10">{formatMessage(messages.noRecordFound)}</td></tr>
                    )
                }
              </tbody>
            </table>
          </div>
          {
            getAllReservationAdmin && getAllReservationAdmin.reservationData && getAllReservationAdmin.reservationData.length > 0
            && <div>
              <CustomPagination
                total={getAllReservationAdmin.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.panelReservation)}
                isScroll
              />
            </div>
          }
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
  completed: state.reservation.completed,
  loading: state.reservation.loading,
  toCurrency: state.currency.to || state.currency.base,
});

const mapDispatch = {
};

export default compose(injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch)
)(ReservationManagement);