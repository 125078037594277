import React from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NavigationBeforeLogin.css';
import { connect } from 'react-redux';

import {
  Nav,
  NavDropdown,
} from 'react-bootstrap';

// Modals
import LoginModal from '../../../LoginModal/LoginModal';
import SignupModal from '../../../SignupModal/SignupModal';
import ForgotPassword from '../../../ForgotPassword/ForgotPassword';
import HeaderModal from '../../../HeaderModal/HeaderModal';

// Components
import NavLink from '../../../NavLink/NavLink';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';

// Locale
import messages from '../../../../locale/messages';

// Actions
import { openLoginModal, openSignupModal } from '../../../../actions/modalActions';
import { openHeaderModal } from '../../../../actions/modalActions';
// import { choseToTheme } from '../../../../actions/getCurrencyRates';

//Images
import globalIconTwo from '../../../../../public/SiteIcons/gobalIconTwo.svg';
import dropDownLightIcon from '../../../../../public/SiteIcons/lightIcon.svg';
import dropDownDarkIcon from '../../../../../public/SiteIcons/darkIcon.svg';
import selectedLightIcon from '../../../../../public/SiteIcons/selectedLightIcon.svg'
import selectedDarkIcon from '../../../../../public/SiteIcons/selectedDarkIcon.svg'

class NavigationBeforeLogin extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    openLoginModal: PropTypes.any,
    openSignupModal: PropTypes.any,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { openLoginModal, openSignupModal, openHeaderModal, choseToTheme, selectedTheme, toggleMenu, isOpen } = this.props;
    return (
      <div>
        <LoginModal />
        <SignupModal />
        <ForgotPassword />
        <HeaderModal modalType={'languageModal'} />
        <HeaderModal modalType={'currencyModal'} />

        <Nav className={cx('header-sidebar-menu floatLeftAR d-flex flex-column d-block d-xl-none pe-4')}>

          <NavLink to="/">
            <FormattedMessage {...messages.home} />
          </NavLink>

          <NavLink
            noLink
            onClick={(e) => openHeaderModal('languageModal')}
            className={cx(s.breakPointScreen)}
          >
            <FormattedMessage {...messages.languageCurrency} />
          </NavLink>

          <NavLink to="/whyhost">
            <FormattedMessage {...messages.becomeAHost} />
          </NavLink>

          <NavLink to="#" noLink onClick={openLoginModal}>
            <FormattedMessage {...messages.login} />
          </NavLink>

          <NavLink to="#" noLink onClick={openSignupModal}>
            <FormattedMessage {...messages.signup} />
          </NavLink>

          <NavLink to="/help">
            <FormattedMessage {...messages.help} />
          </NavLink>

          {/* <NavLink to="#" onClick={() => { choseToTheme('light') }}>
            <span className={cx(s.themeIconAlignment, { [s.selectedThemeColor]: selectedTheme === "light" })}>
              <FormattedMessage {...messages.lightMode} />
              {
                selectedTheme && (selectedTheme === "light") ?
                  <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  : <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
              }
            </span>
          </NavLink> */}

          {/* <NavLink to="#" onClick={() => { choseToTheme('dark') }}>
            <span className={cx(s.themeIconAlignment, { [s.selectedThemeColor]: selectedTheme === "dark" })}>
              <FormattedMessage {...messages.darkMode} />
              {
                selectedTheme && selectedTheme === "dark" ?
                  <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                  <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
              }
            </span>
          </NavLink> */}
        </Nav>

        <Nav className={cx('floatLeftAR d-none d-xl-flex')}>

          <div 
            className={cx(s.floatLeft, s.tabViewHidden, 'floatRight', s.languageFlex)}
            style={{cursor: 'pointer', zIndex: 1}}
          >
            <NavLink to="#"
              noLink
              onClick={(e) => openHeaderModal('languageModal')}
              className={cx(s.breakPointScreen, s.disPlayInline)}
            >
              <img src={globalIconTwo} />
            </NavLink>
          </div>

          <NavDropdown
            onToggle={toggleMenu}
            show={isOpen}
            className={cx(
              s.tabViewHidden,
              s.paddingDropDown,
              'header-dropdown-menu rtlDropDownPaddingHeader'
            )}
            title={
              <DropDownMenu />
            }
          >
            <NavLink to="#" noLink onClick={openLoginModal}>
              <FormattedMessage {...messages.login} />
            </NavLink>

            <NavLink to="/whyhost">
              <FormattedMessage {...messages.becomeAHost} />
            </NavLink>

            <NavLink to="#" noLink onClick={openSignupModal}>
              <FormattedMessage {...messages.signup} />
            </NavLink>

            <NavLink to="/help">
              <FormattedMessage {...messages.help} />
            </NavLink>

            {/* <NavLink to="#" onClick={() => { choseToTheme('light') }} className={'textDecorationNone'}>
              <div className={cx(s.themeIconAlignment, s.selectedThemeColor, { ['textWhite']: selectedTheme === "dark" })}>
                <FormattedMessage {...messages.lightMode} />
                {
                  (selectedTheme && selectedTheme === "light") ?
                    <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                    <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                }
              </div>
            </NavLink>

            <NavLink to="#" onClick={() => { choseToTheme('dark') }} className={'textDecorationNone'}>
              <div className={cx(s.themeIconAlignment, s.textColor, { [s.selectedThemeColor]: selectedTheme === "dark" })}>
                <FormattedMessage {...messages.darkMode} />
                {
                  (selectedTheme && selectedTheme === "dark") ?
                    <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                    <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                }
              </div>
            </NavLink> */}
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

const mapState = state => ({
  baseCurrency: state?.currency?.base,
  toCurrency: state?.currency?.to,
  currentLocale: state?.intl?.locale,
  selectedTheme: state?.currency?.theme

});
const mapDispatch = {
  openLoginModal,
  openSignupModal,
  openHeaderModal,
  // choseToTheme
};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
)(NavigationBeforeLogin);