import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialize } from 'redux-form';
// Redux
import { connect } from 'react-redux';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// Components
import CurrencyConverter from '../CurrencyConverter';

import {
  getPriceWithDiscount,
  calculateGuestCancelValuesForMonthly,
  calculateGuestCancelValuesForNightly,
} from '../../helpers/cancellationData';

// Locale
import messages from '../../locale/messages';

// Images
import defaultPic from '../../../public/SiteImages/large_no_image.jpeg';
import StarImage from '../../../public/SiteIcons/starFill.svg';
import refundIcon from '../../../public/SiteIcons/refundable.svg'
import nonRefundIcon from '../../../public/SiteIcons/nonRefundable.svg'

import bt from '../../components/commonStyle.css';
import s from './Cancellation.css';

class DetailsForGuest extends React.Component {

  render() {
    const { reservationData, cancelData, initialize } = this.props;
    const { formatMessage } = this.props.intl;

    let coverImage = reservationData.listData.listPhotos?.find(o => o.id == reservationData.listData.coverPhoto);
    let path = '/images/upload/x_medium_';
    let showImage;
    if (coverImage) {
      showImage = path + coverImage?.name;
    }
    else if (reservationData.listData.listPhotos?.length > 0) {
      showImage = path + (reservationData.listData.listPhotos[0].name);
    }
    else {
      showImage = defaultPic;
    }

    let roomType;
    if (reservationData.listData.settingsData?.length > 0) {
      roomType = reservationData.listData.settingsData[0].listsettings?.itemName;
    }

    let starRatingValue = 0;
    if (reservationData.listData.reviewsCount > 0 && reservationData.listData.reviewsStarRating > 0) {
      starRatingValue = Math.round(reservationData.listData.reviewsStarRating / reservationData.listData.reviewsCount);
    }

    let cancellationGuestObj = {};
    if (reservationData.isMonthly) {
      let reservationDataForCancel = {
        monthlyRentalPrice: reservationData.monthlyRentalPrice,
        cleaningPrice: reservationData.cleaningPrice,
        reservationGuestServiceFee: reservationData.guestServiceFee,
        reservationHostServiceFee: reservationData.hostServiceFee,
        reservationTotal: reservationData.total,
        deposit: reservationData.deposit,
        completedPayments: reservationData.completedPayments,
        checkIn: reservationData.checkIn,
      };

      cancellationGuestObj = calculateGuestCancelValuesForMonthly(reservationDataForCancel, cancelData.type, cancelData.interval);
      console.log('calculateGuestCancelValuesForMonthly', cancellationGuestObj);
    }
    else {

      let finalPrice = getPriceWithDiscount({
        basePrice: (reservationData.isSpecialPriceAverage || reservationData.listData.basePrice),
        discount: reservationData.discount,
        nights: cancelData.nights,
      });

      cancellationGuestObj = calculateGuestCancelValuesForNightly(
        cancelData.remainingNights,
        cancelData.nights,
        cancelData.accomodation,
        reservationData.cleaningPrice,
        reservationData.guestServiceFee,
        cancelData.guestFees,
        reservationData.hostServiceFee,
        reservationData.taxPrice,
        finalPrice,
        reservationData.total,
        cancelData.nonRefundableNights,
        reservationData.hostServiceFeeType,
        reservationData.hostServiceFeeValue,
        cancelData.cleaningFeePercent,
        cancelData.taxFeePercent,
      );

      console.log('calculateGuestCancelValuesForNightly', cancellationGuestObj);
    }

    let cancellationData = {
      reservationId: reservationData.id,
      isTaxRefunded: cancelData.isTaxRefunded || 0,

      refundToGuest: cancellationGuestObj.refundToGuest || 0,
      payoutToHost: cancellationGuestObj.payoutToHost || 0,
      guestServiceFee: cancellationGuestObj.guestServiceFee || 0,
      hostServiceFee: cancellationGuestObj.hostServiceFee || 0,
      actualCheckout: cancellationGuestObj.actualCheckout || null,
      unblockDatesStarting: cancellationGuestObj.unblockDatesStarting || null,

      cancelledBy: 'guest',
      userType: 'guest',
    };

    initialize('CancellationForm', cancellationData, true);

    return (
      <div>
        <div>
          <a href={"/rooms/" + reservationData.listId} target="_blank">
            <div className={s.cancelBg} style={{ backgroundImage: `url(${showImage})` }}>
            </div>
            <div className={s.listDetailsSection}>
              {starRatingValue > 0 && <div className={cx(s.starFlex, 'textWhite')}>
                <span><img src={StarImage} className={s.starImage} /></span>
                <span>{starRatingValue}</span>
                <span className={cx(s.reviewText, 'darkModeReviewText')}>{reservationData.listData.reviewsCount} {reservationData.listData.reviewsCount > 1 ? formatMessage(messages.reviews) : formatMessage(messages.review)}</span>
              </div>}
              <h3 className={cx(s.listTitle, 'textWhite')}>{reservationData.listData.title}</h3>
              <h4 className={cx(s.listTitleDetails, 'textWhite')}>
                <span className='roomTypeRtl'>{messages[roomType] ? formatMessage(messages[roomType]) : roomType}</span>
                {reservationData.listData.beds > 0 && <span><span>&nbsp;-&nbsp;</span>{reservationData.listData.beds} {reservationData.listData.beds > 1 ? formatMessage(messages.beds) : formatMessage(messages.bed)}</span>}
              </h4>
              <p className={cx(s.listTitleDetails, 'textWhite')}>{reservationData.listData.city}, {reservationData.listData.state}, {reservationData.listData.country}</p>
            </div>
          </a>
        </div>
        <div className={cx(s.refundableBg, 'bgBlackTwo', 'textWhite')}>
          {
            (cancellationGuestObj.notRefundableToGuest > 0) &&
            <div className={s.refundableText}>
              <span className={cx(s.textHigh, s.textBold, s.refundIconSec)}>
                <img src={nonRefundIcon} /> <FormattedMessage {...messages.nonRefundable} />
              </span>
              <span className={cx(s.textHigh, s.textBold, s.textLine)}>
                <CurrencyConverter
                  amount={cancellationGuestObj.notRefundableToGuest}
                  from={reservationData.currency}
                />
              </span>
            </div>
          }

          {
            (cancellationGuestObj.refundToGuest > 0) &&
            <>
              <div className={cx(s.refundableText, s.refundableNoBorder, 'textWhite')}>
                <span className={cx(s.textHigh, s.textBold, s.refundIconSec)}>
                  <img src={refundIcon} /> <FormattedMessage {...messages.refundable} />
                </span>
                <span className={cx(s.textHigh, s.textBold)}>
                  <CurrencyConverter
                    amount={cancellationGuestObj.refundToGuest}
                    from={reservationData.currency}
                  />
                </span>
              </div>

              {
                cancelData.isMonthly &&
                <>
                  <div className={cx(s.refundableText, s.refundableNoBorder, 'textWhite')}>
                    <span className={cx(s.textHigh, s.textBold, s.refundIconSec)}>
                      {/* <FormattedMessage {...messages.refundable} /> */}
                      Deposit
                    </span>
                    <span className={cx(s.textHigh, s.textBold)}>
                      <CurrencyConverter
                        amount={reservationData.deposit}
                        from={reservationData.currency}
                      />
                    </span>
                  </div>

                  <div className={cx(s.refundableText, s.refundableNoBorder, 'textWhite')}>
                    <span className={cx(s.textHigh, s.textBold, s.refundIconSec)}>
                      {/* <FormattedMessage {...messages.refundable} /> */}
                      Total Refundable
                    </span>
                    <span className={cx(s.textHigh, s.textBold)}>
                      <CurrencyConverter
                        amount={cancellationGuestObj.refundToGuest + reservationData.deposit}
                        from={reservationData.currency}
                      />
                    </span>
                  </div>
                </>
              }
            </>
          }

          {
            (cancellationGuestObj.refundToGuest) > 0 &&
            <div className={cx(s.spaceTop2)}>
              <p className={s.landingStep}><span><FormattedMessage {...messages.refundCost} /></span></p>
            </div>
          }
        </div>
        {
          !cancelData.isMonthly &&
          <div className={cx(s.spaceTop3, bt.textAlignCenter, s.cancellation, 'textWhite')}>
            <FormattedMessage {...messages.cancellationPolicy} />:{' '}<a href={'/cancellation-policies/' + cancelData.policyName} target="_blank">
              <span className={s.greenColor}><FormattedMessage {...messages[cancelData.policyName]} /></span></a>
            <div><FormattedMessage {...messages[cancelData.policyContent]} /></div>
          </div>
        }
      </div>
    );
  }
}

const mapState = (state) => ({
  base: state.currency?.base,
  rates: state.currency?.rates
});

const mapDispatch = {
  initialize
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(DetailsForGuest)));