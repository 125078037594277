import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';

// Redux Action
import { openReservationModal } from '../../../actions/Reservation/payoutModal';
// Translation
import messages from '../../../locale/messages';
//Helper
import { decode } from '../../../helpers/queryEncryption'
import { getDateUsingTimeZone } from '../../../helpers/dateRange';

import s from './ReservationManagement.css';

class MonthlyPayout extends Component {
  static propTypes = {
    hostId: PropTypes.string.isRequired,
    checkIn: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    hostPayout: PropTypes.shape({
      id: PropTypes.number.isRequired,
      payEmail: PropTypes.string.isRequired,
      methodId: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      last4Digits: PropTypes.number
    }),
    hostTransaction: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    reservationId: PropTypes.number,
    openReservationModal: PropTypes.any.isRequired,
    transactionData: PropTypes.object,
    hostData: PropTypes.shape({
      userData: PropTypes.shape({
        email: PropTypes.string.isRequired,
      })
    }),
    hostServiceFee: PropTypes.number
  };

  static defaultProps = {
    hostPayout: null,
    reservationId: 0,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { hostId, id, hostPayout, amount, currency, openReservationModal, hostServiceFee, hostData, changeState  } = this.props;

    let amountPaytoHost = amount;
    if (hostServiceFee > 0) {
      amountPaytoHost = amount - hostServiceFee;
    }
    
    let hostEmail;
    if (hostData?.userData) {
      hostEmail = decode(hostData?.userData?.email);
    }

    const formName = 'ReservationPaymentForm';
    const initialData = {
      type: 'host',
      hostId,
      reservationId: id,
      receiverEmail: hostPayout?.payEmail,
      payoutId: hostPayout?.id,
      amount: amountPaytoHost,
      currency, // this is amount currency,
      paymentMethodId: hostPayout?.methodId,
      payoutCurrency: hostPayout?.currency,
      last4Digits: hostPayout?.last4Digits,
      hostEmail,
      changeState,
      isMonthlyPayout: true,
    };
    
    openReservationModal(formName, initialData);
  }

  render() {
    const { id, hostPayout, selectedPayout, completedPayments, completedPayouts, checkIn } = this.props;
    const { formatMessage } = this.props.intl;

    if (hostPayout === null || hostPayout?.payEmail === undefined) {
      return <span>{formatMessage(messages.noPayoutMethod)}</span>
    }

    if (selectedPayout?.includes(id)) {
      return <span className={s.processingtext}>{formatMessage(messages.processingLabel)}</span>;
    }

    let action = false;
    let status = 'Not due';

    let monthlyPayoutDue = false;
    if ((2 <= completedPayments) && (completedPayouts < (completedPayments-1))) {
      let passedDaysFromCheckin = Math.abs(moment(checkIn).diff(moment(), 'days'));
      let currentMonth = Math.ceil(passedDaysFromCheckin / 30); 
      if (2 <= currentMonth) {
        monthlyPayoutDue = true;
      }
    }

    if (monthlyPayoutDue) {
      status = formatMessage(messages.readyTopay);
      action = true;
    }

    return (
      <div>
        {
          action && <a onClick={this.handleClick}>
            {status}
          </a>
        }
        {
          !action && <span>{status}</span>
        }
      </div>
    );
  }
}


const mapState = (state) => ({
  reservationId: state.reservation.reservationId,
});

const mapDispatch = {
  openReservationModal
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MonthlyPayout)));