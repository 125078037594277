import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialize } from 'redux-form';

// Redux
import { connect } from 'react-redux';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Components
import CurrencyConverter from '../CurrencyConverter';

import {
  calculateHostCancelValuesForMonthly,
  calculateHostCancelValuesForNightly,
  getPriceWithDiscount,
} from '../../helpers/cancellationData';

import messages from '../../locale/messages';

// Images
import defaultPic from '../../../public/SiteImages/large_no_image.jpeg';
import StarImage from '../../../public/SiteIcons/starFill.svg';

import bt from '../../components/commonStyle.css';
import s from './Cancellation.css';

class DetailsForHost extends React.Component {

  render() {
    const { reservationData, cancelData, firstName, initialize } = this.props;
    const { formatMessage } = this.props.intl;

    let path = '/images/upload/x_medium_', showImage;
    let coverImage = reservationData.listData.listPhotos?.find(o => o.id == reservationData.listData.coverPhoto);
    if (coverImage) {
      showImage = path + coverImage?.name;
    }
    else if (!coverImage && reservationData.listData.listPhotos?.length > 0) {
      showImage = path + (reservationData.listData.listPhotos[0]?.name);
    }
    else {
      showImage = defaultPic;
    }

    let roomType;
    if (reservationData.listData.settingsData?.length > 0) {
      roomType = reservationData.listData.settingsData[0]?.listsettings?.itemName;
    }

    let starRatingValue = 0;
    if (reservationData.listData.reviewsCount > 0 && reservationData.listData.reviewsStarRating > 0) {
      starRatingValue = Math.round(reservationData.listData.reviewsStarRating / reservationData.listData.reviewsCount)
    }

    let cancellationGuestObj = {}, totalPrice = 0, totalNights = 0;
    if (reservationData.isMonthly) {
      let reservationDataForCancel = {
        monthlyRentalPrice: reservationData.monthlyRentalPrice,
        cleaningPrice: reservationData.cleaningPrice,
        reservationGuestServiceFee: reservationData.guestServiceFee,
        reservationHostServiceFee: reservationData.hostServiceFee,
        reservationTotal: reservationData.total,
        deposit: reservationData.deposit,
        completedPayments: reservationData.completedPayments,
      };

      cancellationGuestObj = calculateHostCancelValuesForMonthly(reservationDataForCancel, cancelData.type, cancelData.interval);
      console.log('calculateHostCancelValuesForMonthly', cancellationGuestObj);
    }
    else {
      totalPrice = getPriceWithDiscount({
        basePrice: (reservationData.isSpecialPriceAverage || reservationData.basePrice),
        discount: reservationData.discount,
        nights: cancelData.nights,
      });

      cancellationGuestObj = calculateHostCancelValuesForNightly({
        total: reservationData.total,
        isCleaningPrice: reservationData.cleaningPrice,
        guestServiceFee: reservationData.guestServiceFee,
        hostServiceFee: reservationData.hostServiceFee,
        taxPrice: reservationData.taxPrice,
        hostServiceFeeType: reservationData.hostServiceFeeType,
        hostServiceFeeValue: reservationData.hostServiceFeeValue,

        basePrice: totalPrice,
        nights: cancelData.nights,
        remainingNights: cancelData.remainingNights,
        interval: cancelData.interval,
      });

      console.log('calculateHostCancelValuesForNightly', cancellationGuestObj);
    }

    let cancellationData = {
      reservationId: reservationData.id,
      isTaxRefunded: cancelData.isTaxRefunded || 0,

      refundToGuest: cancellationGuestObj.refundToGuest || 0,
      payoutToHost: cancellationGuestObj.payoutToHost || 0,
      guestServiceFee: cancellationGuestObj.guestServiceFee || 0,
      hostServiceFee: cancellationGuestObj.hostServiceFee || 0,
      actualCheckout: cancellationGuestObj.actualCheckout || null,
      unblockDatesStarting: cancellationGuestObj.unblockDatesStarting || null,

      cancelledBy: 'host',
      userType: 'host',
    };

    initialize('CancellationForm', cancellationData, true);

    totalNights = cancelData.nights - cancellationGuestObj.refundDays;

    return (
      <div>
        <div>
          <a href={"/rooms/" + reservationData.listId} target="_blank">
            <div className={s.cancelBg} style={{ backgroundImage: `url(${showImage})` }}>
            </div>
            <div className={s.listDetailsSection}>
              {starRatingValue > 0 && <div className={cx(s.starFlex, 'textWhite')}>
                <span><img src={StarImage} className={s.starImage} /></span>
                <span>{starRatingValue}</span>
                <span className={cx(s.reviewText, 'darkModeReviewText')}>{reservationData.listData.reviewsCount} {reservationData.listData.reviewsCount > 1 ? formatMessage(messages.reviews) : formatMessage(messages.review)}</span>
              </div>}
              <h3 className={cx(s.listTitle, 'textWhite')}>{reservationData.listData.title}</h3>
              <h4 className={cx(s.listTitleDetails, 'textWhite')}>
                <span className='roomTypeRtl'>{messages[roomType] ? formatMessage(messages[roomType]) : roomType}</span>
                {reservationData.listData.beds > 0 && <span><span>&nbsp;-&nbsp;</span>{reservationData.listData.beds} {reservationData.listData.beds > 1 ? formatMessage(messages.beds) : formatMessage(messages.bed)}</span>}
              </h4>
              <p className={cx(s.listTitleDetails, 'textWhite')}>{reservationData.listData.city}, {reservationData.listData.state}, {reservationData.listData.country}</p>
            </div>
          </a>
        </div>

        <div className={cx(s.refundableBg, 'bgBlackTwo', 'textWhite')}>
          {
            (cancellationGuestObj.expectedMonthlyPayout > 0) &&
            <div className={s.refundableText} style={{ borderBottom: (cancellationGuestObj.payoutToHost === 0) ? '0px' : '' }}>
              <div>
                <p className={cx(s.textHigh, s.textBold, s.noMargin)}>
                  {/* <FormattedMessage {...messages.missedEarnings} /> */}
                  Expected Monthly Earning
                </p>
                {/* <span>
                  <CurrencyConverter
                    amount={totalPrice || 0}
                    from={reservationData.currency}
                  />
                </span>
                <span> {' x'} {cancellationGuestObj.refundDays} {cancellationGuestObj.refundDays > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}</span> */}
              </div>
              <div className={cx(s.textHigh, s.textBold, s.textLine)}>
                <CurrencyConverter
                  amount={cancellationGuestObj.expectedMonthlyPayout}
                  from={reservationData.currency}
                />
              </div>
            </div>
          }
          {
            !cancelData.isMonthly && (cancellationGuestObj.nonPayoutAmount > 0) &&
            <div className={s.refundableText} style={{ borderBottom: (cancellationGuestObj.payoutToHost === 0) ? '0px' : '' }}>
              <div>
                <p className={cx(s.textHigh, s.textBold, s.noMargin)}>
                  <FormattedMessage {...messages.missedEarnings} />
                </p>
                {/* <span>
                  <CurrencyConverter
                    amount={totalPrice || 0}
                    from={reservationData.currency}
                  />
                </span>
                <span> {' x'} {cancellationGuestObj.refundDays} {cancellationGuestObj.refundDays > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}</span> */}
              </div>
              <div className={cx(s.textHigh, s.textBold, s.textLine)}>
                <CurrencyConverter
                  amount={cancellationGuestObj.nonPayoutAmount}
                  from={reservationData.currency}
                />
              </div>
            </div>
          }
          <div className={cx(s.refundableText, s.refundableNoBorder)}>
            <div>
              <p className={cx(s.textHigh, s.textBold, s.noMargin)}>
                <FormattedMessage {...messages.earnings} />
              </p>
              {/* <span>
                  <CurrencyConverter
                    amount={totalPrice || 0}
                    from={reservationData.currency}
                  />
                </span>
                {totalNights > 0 && <span> x {totalNights} {formatMessage(messages[totalNights > 1 ? 'nights' : 'night'])},</span>} */}
            </div>
            <div className={cx(s.textHigh, s.textBold)}>
              <CurrencyConverter
                amount={cancellationGuestObj.payoutToHost}
                from={reservationData.currency}
              />
            </div>
          </div>
          <div className={cx(s.spaceTop2)}>
            <p className={s.landingStep}>
              <span>{firstName}{' '}
                <FormattedMessage {...messages.willBeRefund} />{' '}{' '}
                <FormattedMessage {...messages.reservationCost} />
              </span>
            </p>
          </div>
        </div>
        {
          !cancelData.isMonthly &&
          <div className={cx(s.spaceTop3, bt.textAlignCenter, s.cancellation, 'textWhite')}>
            <FormattedMessage {...messages.cancellationPolicy} />:{' '}<a href={'/cancellation-policies/' + cancelData.policyName} target="_blank">
              <span className={s.greenColor}><FormattedMessage {...messages[cancelData.policyName]} /></span></a>
            <div><FormattedMessage {...messages[cancelData.policyContent]} /></div>
          </div>
        }
      </div>
    );
  }
}

const mapState = (state) => ({
});

const mapDispatch = {
  initialize
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(DetailsForHost)));