import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import {
  Nav, 
  NavDropdown, 
  Button,
} from 'react-bootstrap';

// Components
import NavLink from '../../../NavLink/NavLink';
import Message from '../../../Message/Message';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';

// Modals
import HeaderModal from '../../../HeaderModal/HeaderModal';

// Locale
import messages from '../../../../locale/messages';

// Actions
import { openHeaderModal } from '../../../../actions/modalActions';
// import { choseToTheme } from '../../../../actions/getCurrencyRates';
import { setUserLogout } from '../../../../actions/logout';

// Queries
import UserBanStatusQuery from './getUserBanStatus.graphql';
import CheckUserStatusQuery from './getCheckUserStatus.graphql';
import UserStatusQuery from './getUserStatus.graphql';

//Images
import globalIconTwo from '../../../../../public/SiteIcons/gobalIconTwo.svg';
import dropDownLightIcon from '../../../../../public/SiteIcons/lightIcon.svg';
import dropDownDarkIcon from '../../../../../public/SiteIcons/darkIcon.svg';
import selectedLightIcon from '../../../../../public/SiteIcons/selectedLightIcon.svg'
import selectedDarkIcon from '../../../../../public/SiteIcons/selectedDarkIcon.svg'
import blackLightMode from '../../../../../public/SiteIcons/blackLightMode.svg'

import s from './NavigationAfterLogin.css';

class NavigationAfterLogin extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    setUserLogout: PropTypes.any,
    formatMessage: PropTypes.any,
    loginUserBanStatus: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getUserBanStatus: PropTypes.shape({
        userBanStatus: PropTypes.number,
      }),
    }),
  };
  static defaultProps = {
    loginUserBanStatus: {
      loading: true,
      getUserBanStatus: {
        userBanStatus: 0,
      },
    },
    userDeleteStatus: {
      userLoading: true,
      getUserStatus: {
        userStatus: null,
      },
    },
    checkLoginUserExist: {
      userExistloading: true,
      getCheckUserStatus: {
        userExistStatus: null,
      },
    }
  };

  render() {
    const { 
      className, 
      setUserLogout, 
      userData, 
      openHeaderModal,
      // choseToTheme, 
      selectedTheme, 
      page,
      toggleMenu,
      isOpen,
      loginUserBanStatus: { 
        loading, 
        getUserBanStatus 
      }, 
      userDeleteStatus: { 
        userLoading, 
        getUserStatus 
      },
      checkLoginUserExist: { 
        userExistloading, 
        getCheckUserStatus 
      }, 
    } = this.props;

    if (!userExistloading && getCheckUserStatus) {
      if (getCheckUserStatus.userExistStatus) {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
          setUserLogout({ isAdmin: false });
        }
      }
    }
    if (!loading && getUserBanStatus) {
      if (getUserBanStatus.userBanStatus) {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
          setUserLogout({ isAdmin: false });
        }
      }
    }
    if (!userLoading && getUserStatus) {
      if (getUserStatus.userStatus) {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
          setUserLogout({ isAdmin: false });
        }
      }
    }
    return (
      <div>
        <HeaderModal modalType={'languageModal'} />
        <HeaderModal modalType={'currencyModal'} />

        <Nav className={cx('header-sidebar-menu floatLeftAR d-flex flex-column d-block d-xl-none')}>

          <NavLink to="/">
            <FormattedMessage {...messages.home} />
          </NavLink>

          <NavLink
            noLink
            onClick={(e) => openHeaderModal('languageModal')}
            className={cx(s.breakPointScreen)}
          >
            <FormattedMessage {...messages.languageCurrency} />
          </NavLink>

          <NavLink to="/whyhost">
            <FormattedMessage {...messages.becomeAHost} />
          </NavLink>
          
          <div className={s.menuDivider}></div>

          <NavLink to="/dashboard">
            <FormattedMessage {...messages.dashboard} />
          </NavLink>

          <NavLink to="/become-a-host?mode=new">
            <FormattedMessage {...messages.createListing} />
          </NavLink>

          <NavLink to="/rooms">
            <FormattedMessage {...messages.hosting} />
          </NavLink>

          <NavLink to="/trips/current">
            <FormattedMessage {...messages.traveling} />
          </NavLink>
        
          <Message/>
          
          <div className={s.menuDivider}></div>

          <NavLink to="/user/payout">
            <FormattedMessage {...messages.account} />
          </NavLink>

          <NavLink to="/user/edit">
            <FormattedMessage {...messages.editProfile} />
          </NavLink>

          <NavLink to="/wishlists">
            <FormattedMessage {...messages.wishList} />
          </NavLink>

          <NavLink to="/help">
            <FormattedMessage {...messages.help} />
          </NavLink>

          {/* <NavLink to="#" onClick={() => { choseToTheme('light') }}>
            <span className={cx(s.themeIconAlignment, { [s.selectedThemeColor]: selectedTheme === "light" })}>
              <FormattedMessage {...messages.lightMode} />
              {
                selectedTheme && (selectedTheme === "light") ?
                  <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                  : <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
              }
            </span>
          </NavLink>

          <NavLink to="#" onClick={() => { choseToTheme('dark') }}>
            <span className={cx(s.themeIconAlignment, { [s.selectedThemeColor]: selectedTheme === "dark" })}>
              <FormattedMessage {...messages.darkMode} />
              {
                selectedTheme && selectedTheme === "dark" ?
                  <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                  <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
              }
            </span>
          </NavLink> */}
            
          <div className={s.menuDivider}></div>
          
          <div className="pe-4">
            <form action="/logout" method="post">
              <Button type="submit" variant="link" className={s.logoutBtn}>
                <FormattedMessage {...messages.logout} />
              </Button>
            </form>
          </div>

        </Nav>

        <Nav className={cx('floatLeftAR d-none d-xl-flex align-items-center')}>

          <div 
            className={cx(s.floatLeft, s.tabViewHidden, 'floatRight', s.languageFlex)}
            style={{cursor: 'pointer', zIndex: 1}}
          >
            <NavLink to="#"
              noLink
              onClick={(e) => openHeaderModal('languageModal')}
            >
              <img src={globalIconTwo} />
            </NavLink>
          </div>

          <NavDropdown
            onToggle={toggleMenu}
            show={isOpen}
            className={cx(
              s.tabViewHidden, 
              s.paddingDropDown, 
              'header-dropdown-menu rtlDropDownPaddingHeader'
            )} 
            title={
              <DropDownMenu />
            }
          >
            <NavLink to="/dashboard">
              <FormattedMessage {...messages.dashboard} />
            </NavLink>

            <NavLink to="/become-a-host?mode=new">
              <FormattedMessage {...messages.createListing} />
            </NavLink>

            <NavLink to="/rooms">
              <FormattedMessage {...messages.hosting} />
            </NavLink>

            <NavLink to="/trips/current">
              <FormattedMessage {...messages.traveling} />
            </NavLink>
        
            <Message/>
            
            <div className={s.menuDivider}></div>

            <NavLink to="/user/payout">
              <FormattedMessage {...messages.account} />
            </NavLink>

            <NavLink to="/user/edit">
              <FormattedMessage {...messages.editProfile} />
            </NavLink>

            <NavLink to="/wishlists">
              <FormattedMessage {...messages.wishList} />
            </NavLink>

            <NavLink to="/whyhost">
              <FormattedMessage {...messages.becomeAHost} />
            </NavLink>
            
            {/* <NavLink to="#" onClick={() => { choseToTheme('light') }} className={'textDecorationNone'}>
              <div className={cx(s.themeIconAlignment, s.selectedThemeColor, { ['textWhite']: selectedTheme === "dark" })}>
                <FormattedMessage {...messages.lightMode} />
                {
                  (selectedTheme && selectedTheme === "light") ?
                    <img src={selectedLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                    <img src={dropDownLightIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                }
              </div>
            </NavLink>

            <NavLink to="#" onClick={() => { choseToTheme('dark') }} className={'textDecorationNone'}>
              <div className={cx(s.themeIconAlignment, s.textColor, { [s.selectedThemeColor]: selectedTheme === "dark" })}>
                <FormattedMessage {...messages.darkMode} />
                {
                  (selectedTheme && selectedTheme === "dark") ?
                    <img src={selectedDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} /> :
                    <img src={dropDownDarkIcon} alt='' className={cx(s.themeIcon, 'themeIconRTL')} />
                }
              </div>
            </NavLink> */}
            
            <div className={s.menuDivider}></div>
            
            <NavLink to="/help">
              <FormattedMessage {...messages.help} />
            </NavLink>
            
            <div>
              <form action="/logout" method="post">
                <Button type="submit" variant="link" className={s.logoutBtn}>
                  <FormattedMessage {...messages.logout} />
                </Button>
              </form>
            </div>

          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

const mapState = state => ({
  userData: state.account.data,
  baseCurrency: state?.currency?.base,
  toCurrency: state?.currency?.to,
  currentLocale: state?.intl?.locale,
  selectedTheme: state?.currency?.theme

});
const mapDispatch = {
  setUserLogout,
  openHeaderModal,
  // choseToTheme
};

export default
  compose(
    injectIntl,
    withStyles(s),
    graphql(UserBanStatusQuery, {
      name: 'loginUserBanStatus',
      options: {
        ssr: false,
        // pollInterval: 5000,
      },
    }),
    graphql(UserStatusQuery, {
      name: 'userDeleteStatus',
      options: {
        ssr: false,
        // pollInterval: 5000,
      },
    }),
    graphql(CheckUserStatusQuery, {
      name: 'checkLoginUserExist',
      options: {
        ssr: false,
        // pollInterval: 5000,
      },
    }),
    (connect(mapState, mapDispatch)))(NavigationAfterLogin);