import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import ReceiptContainer from './ReceiptContainer';
import Layout from '../../components/Layout';
import NotFound from '../notFound/NotFound';
import messages from '../../locale/messages';

const title = 'View Receipt';

export default async function action({ store, query, params, intl }) {

  const title = intl?.formatMessage(messages.viewReceipt);

  // From Redux Store
  const isAuthenticated = store.getState().runtime.isAuthenticated;
  const reservationId = params.reservationId;

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  if (!reservationId) {
    return {
      title,
      component: <Layout><NotFound title={title} /></Layout>,
      status: 404
    };
  }

  let renewSuccess = false;
  if (('renew' in query) && ('1' === query.renew)) {
    renewSuccess = true;
  }

  let transactionId = null;
  if ('transactionid' in query) {
    transactionId = Number(query.transactionid);
  }

  return {
    title,
    component:
      <UserLayout>
        <ReceiptContainer 
          title={title}
          reservationId={Number(reservationId)}
          renewSuccess={renewSuccess}
          transactionId={transactionId}
        />
      </UserLayout>
    ,
  };
}
